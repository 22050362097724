import React, { useEffect, useState } from "react";
import { Form, Nav, Tab } from "react-bootstrap";
import { useQueryClient, useMutation, useQuery, QueryClient } from "react-query";
import { gtmScripts, insertScript, scripts } from "../dataService/DataService";
import { HandelScripts } from "./components/HandelScriptSorting";
import { useFormik } from "formik";
import { toast } from "react-toastify";

const Scripts = () => {
  const client=useQueryClient()
  const allScripts = useQuery("scripts", scripts);
  const allGtmScripts = useQuery("gtmScritps", gtmScripts);
  const updateMutation = useMutation(insertScript, {
    onSuccess: (data) => {
      toast.dismiss();
      toast.success("scripts updated successfully");
      client.invalidateQueries("gtmScritps")
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error?.message || "something went wrong");
    },
    onMutate: () => {},
  });

  const getInitialData = (code) => {
    const data = allGtmScripts?.data?.data;

    if (!data) {
      return null;
    }
    const value = data?.filter((item) => item.code === code)[0]?.value ;
    return value;
  };

  const initialValues = {
    //these are for the gtm scripts only
    gtmhead: getInitialData("GTMHEAD") || "",
    gtmbody: getInitialData("GTMBODY") || "",
  };
  

  const { values, setValues, errors, handleChange, handleSubmit,dirty } = useFormik({
    initialValues,
    // validationSchema:
    onSubmit: () => {
      updateMutation.mutate({
        playLoad:values,
        gtm:true
      })
    },
  });

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues.gtmbody, initialValues.gtmhead]);

  const [headStart, setheadStart] = useState([]);
  const [headEnd, setheadEnd] = useState([]);
  const [bodyStart, setbodyStart] = useState([]);
  const [bodyEnd, setbodyEnd] = useState([]);


  useEffect(() => {
    const data = allScripts?.data?.data;
    if (!data) {
      return;
    }
    data?.map((item) => {
      if (item.location_id === "1") {
        setheadStart((prev) => [...prev, item]);
      }
      if (item.location_id === "2") {
        setheadEnd((prev) => [...prev, item]);
      }
      if (item.location_id === "3") {
        setbodyStart((prev) => [...prev, item]);
      }
      if (item.location_id === "4") {
        setbodyEnd((prev) => [...prev, item]);
      }
    });
    return () => {
      setbodyEnd([]);
      setbodyStart([]);
      setheadStart([]);
      setheadEnd([]);
    };
  }, [allScripts?.data?.data, allScripts?.data]);

  if (!allScripts.isSuccess) {
    return;
  }

  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <h4 className="fw-normal m-0">Manage Scripts</h4>
          </div>

          <div className="row mt-lg-5 mt-md-4 mt-3">
            <div className="col-md-12">
              <div className="card mb-4">
                <Tab.Container defaultActiveKey="metaTags">
                  <Nav variant="tabs" className="mb-4">
                    <Nav.Item>
                      <Nav.Link eventKey="metaTags">
                        GOOGLE TAG MANAGER SCRIPTS
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="ogTags">OTHER SCRIPTS </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content>
                    {/* META TAGS Tab */}
                    <Tab.Pane eventKey="metaTags" className="m-4">
                      <div>
                        <Form.Group className="mb-4">
                          <Form.Label
                            htmlFor="ogSiteName"
                            className="text-capitalize"
                          >
                            Google tag manager head start Script{" "}
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows="3"
                            id="ogSiteName"
                            value={values?.gtmhead}
                            onChange={handleChange}
                            className="py-3 shadow-none px-4"
                            name="gtmhead"
                          />
                        </Form.Group>
                        <Form.Group className="mb-4">
                          <Form.Label
                            htmlFor="ogSiteName"
                            className="text-capitalize"
                          >
                            Google tag manager body start scripts{" "}
                          </Form.Label>
                          <Form.Control
                            value={values?.gtmbody}
                            as="textarea"
                            onChange={handleChange}
                            rows="3"
                            id="ogSiteName"
                            className="py-3 shadow-none px-4"
                            name="gtmbody"
                          />
                        </Form.Group>
                      </div>
                      <div className="d-flex justify-content-end my-2">
                        <button
                          onClick={() => {
                            handleSubmit();
                          }}
                          className="btn btn-primary btn-large "
                        >
                          Save
                        </button>
                      </div>
                    </Tab.Pane>

                    {/* OG TAGS Tab */}
                    <Tab.Pane eventKey="ogTags" className="m-4">
                      <div className="my-2">
                        <HandelScripts
                          setterScript={setheadStart}
                          heading="Head start"
                          scriptArray={headStart}
                          location_id="1"
                        />
                      </div>
                      <div className="my-2">
                        <HandelScripts
                          setterScript={setheadEnd}
                          heading="head end"
                          scriptArray={headEnd}
                          location_id="2"
                        />
                      </div>
                      <div className="my-2">
                        <HandelScripts
                          setterScript={setbodyStart}
                          heading="Body start"
                          scriptArray={bodyStart}
                          location_id="3"
                        />
                      </div>
                      <div className="my-2">
                        <HandelScripts
                          setterScript={setbodyEnd}
                          heading="Body end"
                          scriptArray={bodyEnd}
                          location_id="4"
                        />
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Scripts;
