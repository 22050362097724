import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAllPropertiesTypes } from "../../services/service";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { hasDuplicates } from "../../helper/CommonHelper";
import AmenitiesTab1 from "./AmenitiesTab1";

import pdfIcon from "../../assets/images/pdf-doc.svg";
import videoImg from "../../assets/images/video.png";
import Modal from "react-bootstrap/Modal";
import MediaFolderView from "../manageAlbum/MediaFolderViewSelection";
import ManageAlbumSelection from "../manageAlbum/ManageAlbumSelection";
import AlbumViewSelectionForProperties from "../manageAlbum/AlbumViewSelectionForProperties";
import * as querydata from "../dataService/DataService";
import { updateProperty } from "../dataService/DataService";
import AlbumViewSelectionDynamicAmenityIcon from "../manageAlbum/AlbumViewSelectionDynamicAmenityIcon";
import SpecificationsTabEdit from "../manageProperties/SpecificationTabEdit";
import backarrow from "../../assets/images/back-arrow.svg";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { BsFillInfoCircleFill } from "react-icons/bs";
// import DraggableImage from "./DraggableImage";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DragAbleImage from "./DragAbleImage";
import Button from "react-bootstrap/Button";

import { HiPlus } from "react-icons/hi";
import ImageTagModal from "../../components/ImageTagModal";
import VenueFeatures from "./VenueFeatures";
import EditVenueFeatures from "./EditVenueFeatures";
import VenueSittingPlan from "./VenueSittingPlan";
import AlbumViewForDinning from "../manageAlbum/AlbumViewForDinning";
import OGtags from "./OGtags";

export const propertySchema = Yup.object({
  venueName: Yup.string().trim().required("Please enter Venue Name"),

  slugName: Yup.string()
    .matches(
      /^[a-zA-Z0-9_-]*$/,
      "Only alphanumeric characters, hyphens, and underscores are allowed"
    )
    .required("Please enter slug"),
  metaTitle: Yup.string().trim(),
  keyWords: Yup.string().trim(),
  metaDescription: Yup.string().trim(),

  description: Yup.string().trim().required("please enter description"),

  metaTitle: Yup.string().trim(),

  keyWords: Yup.string().trim(),
  metaDescription: Yup.string().trim(),

  // capacity: Yup.number()
  //   .typeError("Capacity must be a number")
  //   .positive("Capacity must be a positive number"),
  // .required("Please enter capacity"),
  // dimension:Yup.string()
  // .required("Please enter capacity"),
  space: Yup.number()
    .typeError("Space must be a number")
    .positive("Space must be a positive number")
    .required("Please enter space"),

  // venueTypeId: Yup.number()
  //   .required("venue type is reuquired ")
  //   .typeError("please select a valid venue "),
});
export default function EditVenues() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setErrors] = useState({});
  const [offerfile, setofferfile] = useState("");
  const [image, setImage] = useState(null);
  const [unitPlanPdf, setUnitPlanPdf] = useState(null);
  // crop code start
  const [folderId, setFolderId] = useState("");
  const [albumId, setAlbumId] = useState("");
  const [websiteId, setwebsiteId] = useState("");
  const [maincroppedImageSorting, setmainCroppedImageSorting] = useState([]);
  const [maincroppedImage, setmainCroppedImage] = useState([]);
  const [maincroppedFile, setmainCroppedFile] = useState([]);
  const [ogImagePlaceholer, setOgImage] = useState(null);
  const [ogImagePlaceholerValue, setOgImageValue] = useState(null);

  const [singleFileProperties, setSingleFileProperties] = useState(null);
  const [singleCropImageProp, setSingleCropImageProp] = useState(null);
  const [checkValForSingleImg, setValForSingleImg] = useState("");
  const [checkMultipleImageSection, setMultipleImageSection] = useState("");
  const [isAmenityIcon, setIsAmenityIcon] = useState("");
  const [amenityFileData, setAmenityFile] = useState("");
  const [amenityCropImage, setAmenityCropImage] = useState("");
  const [AllPropertiesImages, setAllPropertiesImages] = useState([]);
  const [BannerImage, setBannerImage] = useState(null);
  const [actions, setActions] = useState(null);
  const [maincroppedBannerImage, setMainCroppedBannerImage] = useState(null);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [mainCroppedBannerImageFile, setmainCroppedBannerImageFile] =
    useState(null);

  const [checkValForBannerImage, setValForBannerImage] = useState(null);
  const [deletedMultiImageArray, setMultiDeletedImgArray] =
    useState(AllPropertiesImages);
  const [mediaType, setMediaType] = useState("");
  const [cropMediaModal, setCropMediaModal] = useState(false);
  const ToolLink = ({ id, children, title }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
      <a className="text-dark">{children}</a>
    </OverlayTrigger>
  );

  const [settingValue, setSettingValue] = useState("");

  const bannerImageRatio = useQuery(
    ["AspectRatio", "property_inner_banner_image_dimension"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("property_inner_banner_image_dimension")
  );

  const getAspectRatio = useQuery(
    ["AspectRatio", "venue_image_dimension"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("venue_image_dimension")
  );

  const amenityIconDimension = useQuery(
    ["AspectRatio", "amenity_icon_dimension"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("amenity_icon_dimension")
  );
    const getOgAspectRatio = useQuery(
      ["AspectRatio", "og_image_aspect_ratio"], // Using an array as the query key with offerId
      () => querydata.getAspectRatio("og_image_aspect_ratio")
    );
  

  const [restMenu, setrestMenu] = useState([]);
  const [restMenuValue, setrestMenuValue] = useState();
  const [indexForMenu, setIndexForMenu] = useState(1);

  const showCropMediaModal = (id, propValue, index) => {
    if (propValue === "singleUnitPlanImage") {
      setValForSingleImg(propValue);
      setMultipleImageSection("");
      setValForBannerImage("");
      setrestMenuValue("");
      setSettingValue(
        getAspectRatio?.data?.data?.setting_value
          ? getAspectRatio?.data?.data?.setting_value
          : 1 / 1
      );
    }
    // if (propValue === "BannerImage") {
    //   setSettingValue(bannerImageRatio?.data?.data?.setting_value ? bannerImageRatio?.data?.data?.setting_value : 1 / 1);

    //   setValForBannerImage(propValue);
    //   setMultipleImageSection("");
    //   setValForSingleImg(null);
    //   setIsAmenityIcon("");
    // }

    if (propValue === "retsmenu") {
      setIndexForMenu(index);
      setrestMenuValue(propValue);
      setValForSingleImg("");
      setValForBannerImage("");
      setMultipleImageSection("");
    }

    if (propValue === "multipleImageSectionBtn") {
      setMultipleImageSection(propValue);
      setValForSingleImg("");
      setValForBannerImage("");
      setrestMenuValue("");
      setSettingValue(
        getAspectRatio?.data?.data?.setting_value
          ? getAspectRatio?.data?.data?.setting_value
          : 1 / 1
      );
    }
    if (propValue === "amenityIcon") {
      setIsAmenityIcon(propValue);
      setValForBannerImage("");
      setrestMenuValue("");

      setSettingValue(
        amenityIconDimension?.data?.data?.setting_value
          ? amenityIconDimension?.data?.data?.setting_value
          : 1 / 1
      );
    }


    if (propValue === "OGIMAGE") {
      setMultipleImageSection("");
      setValForSingleImg("");
      setValForBannerImage("");
      setOgImageValue(propValue);
      setSettingValue(
        getOgAspectRatio?.data?.data?.setting_value
          ? getOgAspectRatio?.data?.data?.setting_value
          : 1 / 1
      );
    }

    setCropMediaModal(true);
  };

  const closeCropMediaModal = () => setCropMediaModal(false);
  // const handleBannerImage = (e, image) => {
  //   image = undefined;
  //   // Handle removing the image
  //   if (e) {
  //     setBannerImage(undefined);
  //     setMainCroppedBannerImage(undefined);
  //   }
  // };

  const [showModalImageTagModal, setImageTagModal] = useState(false);
  const handleShowImageTagModal = () => {
    setImageTagModal(true);
  };

  const handleCloseImageTagModal = () => {
    setImageTagModal(false);
  };

  // crop code end

  let allPropertiesTypes = useQuery(
    "getAllPropertiesTypes",
    querydata.getVenueCatagories
  );

  const [poolSizeValue, setPoolSizeValue] = useState({});
  const { id } = useParams();
  const getSingleProperty = useQuery(["getSinglevenue", id], () =>
    querydata.getSingleVenue(id)
  );

  const [duplicateIndexes, setDuplicateIndexes] = useState([]);
  let formDataError = false;

  const uniqueFileIds = new Set();
  // console.log("getSingleProperty",getSingleProperty)
  useEffect(() => {
    setFieldValue("amenitiesId", initialValues.amenitiesId);

    setValues({ ...initialValues });

    const originalUrl = getSingleProperty?.data?.data?.venue_data?.pdf_link;

    const modifiedUrl =
      originalUrl &&
      originalUrl.replace("http://172.16.1.127:7000", "http://localhost:7000");

    setImage(modifiedUrl);
    setSingleCropImageProp(modifiedUrl);
    // const bannerImageUrl =
    //   getSingleProperty?.data?.data?.[0]?.property_data?.[0]
    //     ?.inner_banner_image;
    // setMainCroppedBannerImage(bannerImageUrl);

    if (
      getSingleProperty?.data?.data &&
      typeof getSingleProperty.data.data === "object"
    ) {
      const resultData = getSingleProperty.data.data.sitting_plan_data;
      if (resultData && Array.isArray(resultData) && resultData.length > 0) {
        // Map through the array to extract all sitting_pdf values
        const stPlanData = resultData.map((plan) => plan.sitting_pdf || []);
        setrestMenu(stPlanData);
      }
    }

    const imagesData = getSingleProperty?.data?.data?.images_data;

    setAllPropertiesImages(imagesData);
    setVenueTypeId(
      getSingleProperty?.data?.data?.venue_data?.venue_type_id || ""
    );
    if (Array.isArray(imagesData) && imagesData.length > 0) {
      const modifiedImageUrls = imagesData.map((image) =>
        image.image_path.replace(
          "http://172.16.1.127:7000",
          "http://localhost:7000"
        )
      );

      setmainCroppedImage(modifiedImageUrls);
      setmainCroppedImageSorting(modifiedImageUrls);
      setmainCroppedFile((prevImages) => {
        const imagesArray = Array.isArray(prevImages) ? prevImages : [];
        return [...imagesArray, ...modifiedImageUrls];
      });
    }
  }, [getSingleProperty?.data]);

  useEffect(() => {
    const ogImage =  getSingleProperty?.data?.data.venue_data?.og_image ?? null;
    setOgImage(ogImage);
  }, [getSingleProperty?.data]);

  const initialValues = {
    slugName: getSingleProperty?.data?.data.venue_data?.slug || "",
    capacity: getSingleProperty?.data?.data.venue_data?.capacity || "",
    dimension: getSingleProperty?.data?.data.venue_data?.dimension || "",
    space: getSingleProperty?.data?.data.venue_data?.space || "",
    metaTitle: getSingleProperty?.data?.data.venue_data?.meta_title || "",
    keyWords: getSingleProperty?.data?.data.venue_data?.keywords || "",
    metaDescription:
      getSingleProperty?.data?.data.venue_data?.meta_description || "",

    venueTypeId: getSingleProperty?.data?.data?.venue_data?.venue_type_id || "",
    venueName: getSingleProperty?.data?.data.venue_data?.venue_name || "",
    description: getSingleProperty?.data?.data.venue_data?.description || "",
    venueCatId: getSingleProperty?.data?.data.venue_data?.venue_cat_id || "",

    // og tags
    ogTitle: getSingleProperty?.data?.data.venue_data?.og_title || "",
    ogDescription:getSingleProperty?.data?.data.venue_data?.og_description || "",
    ogType: getSingleProperty?.data?.data.venue_data?.og_type || "",
    ogUrl: getSingleProperty?.data?.data.venue_data?.og_url || "",
    // ogImage: ogImagePlaceholer,
    ogSiteName:getSingleProperty?.data?.data.venue_data?.og_site_name || "",

    amenitiesId: (() => {
      return getSingleProperty?.data?.data?.features_data?.map(
        (amdata) => amdata?.venue_feature_id
      );

      // return [...propertyData?.amenity_data];
    })(),

    deletedMultiImageArray: (() => {
      if (deletedMultiImageArray && deletedMultiImageArray.length > 0) {
        return deletedMultiImageArray.map((DltImgData, index) => {
          // perform operations on DltImgData if needed
          return DltImgData;
        });
      } else if (AllPropertiesImages && AllPropertiesImages.length > 0) {
        // If deletedMultiImageArray is empty, return AllPropertiesImages
        return AllPropertiesImages.map((imgData, index) => {
          // perform operations on imgData if needed
          return imgData;
        });
      } else {
        // Handle the case where both deletedMultiImageArray and AllPropertiesImages are empty or undefined
        return [];
      }
    })(),
    features: (() => {
      let serviceFeatureArray = [];

      if (
        getSingleProperty?.data?.data &&
        typeof getSingleProperty.data.data === "object"
      ) {
        // Accessing service_feature_data directly
        const serviceFeatureData =
          getSingleProperty?.data?.data?.service_feature_data;

        if (
          serviceFeatureData &&
          Array.isArray(serviceFeatureData) &&
          serviceFeatureData?.length > 0
        ) {
          // Extract relevant fields from service_feature_data (e.g., service_feature)
          serviceFeatureArray = serviceFeatureData?.map(
            (feature) => feature?.feature_and_service || []
          );
        }
      }

      return serviceFeatureArray;
    })(),
    sittingPlanPdfName: (() => {
      let sittingPlanArray = [];

      if (
        getSingleProperty?.data?.data &&
        typeof getSingleProperty.data.data === "object"
      ) {
        // Accessing sitting_plan_data directly from the data object
        const resultData = getSingleProperty.data.data.sitting_plan_data;

        if (resultData && Array.isArray(resultData) && resultData.length > 0) {
          // Map through the array to extract all sitting_pdf values
          sittingPlanArray = resultData?.map(
            (plan) => plan.sitting_plan_name || []
          );
        }
      }

      return sittingPlanArray;
    })(),
    // featureServices :
    // (() => {
    //   let sittingPlanArray = [];

    //   if (
    //     getSingleProperty?.data?.data &&
    //     typeof getSingleProperty.data.data === 'object'
    //   ) {
    //     // Accessing sitting_plan_data directly from the data object
    //     const resultData = getSingleProperty.data.data.sitting_plan_data;

    //     if (
    //       resultData &&
    //       Array.isArray(resultData) &&
    //       resultData.length > 0
    //     ) {
    //       // Map through the array to extract all sitting_pdf values
    //       sittingPlanArray = resultData.map((plan) => plan.sitting_plan_name	 || []);
    //     }
    //   }

    //   return sittingPlanArray;
    // })(),
    // getSingleProperty?.data?.data?.data[0]?.menu?.map(
    //   (item) => item?.menu_name
    // ) || []
  };
  console.log("getSingleProperty", getSingleProperty);

  const venueNameRef = useRef(null);
  const descriptionRef = useRef(null);
  const propertyImagesRef = useRef(null);
  const slugRef = useRef(null);
  const capacityRef = useRef(null);
  const dimensionRef = useRef(null);
  const spaceRef = useRef(null);
  const venueTypeIdRef = useRef(null);

  const handlePropertyNameChange = (event) => {
    const value = event.target.value;

    setFieldValue("venueName", value);
    // setFieldValue("slugName", value.toLowerCase().replace(/\s+/g, "-"));
  };
  const handleKeyPress = (event) => {
    const enteredChar = event.key;

    // Prevent typing characters other than lowercase letters (a-z) and hyphens (-)
    if (!/^[a-z0-9\-]$/i.test(enteredChar)) {
      event.preventDefault();
    }
  };

  const validateBed = (bed) => {
    for (let i of bed) {
      if (i === 0 || i === null) {
        return false;
      }
    }
    return true;
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: propertySchema,
    onSubmit: async (values, action) => {
      const formData = new FormData();
      // if (values?.amenitiesId?.length === 0) {
      //   toast.dismiss();
      //   toast.error("please select at least one feature ");
      //   return;
      // }
      if (values.amenitiesId) {
        values.amenitiesId.forEach((amtId, index) => {
          formData.append(`features[${index}]`, amtId);
        });
      }

      const featuresArray = Object.values(values.features);
      if (featuresArray) {
        featuresArray.map((value, index) => {
          formData.append(`featuresAndService[${index}]`, value);
        });
      }
      if (values.sittingPlanPdfName) {
        let flag = true;
        const menuArray = Object.values(values.sittingPlanPdfName);
        menuArray.map((menuTags, index) => {
          const trimmedTag = menuTags.trim();
          if (trimmedTag !== "") {
            formData.append(`sittingPlan[${index}]`, menuTags);
          }
        });
        let OldData = [];

        restMenu?.map((item) => {
          if (item !== "") {
            if (typeof item !== "string") {
              formData.append(`menu`, item);
            } else {
              OldData?.push(item?.split("images/")[1]);
            }
          }
          // else {
          //   toast.dismiss();
          //   toast.error("please upload menu item ")
          //   flag = false
          //   return;
          // }
        });

        if (OldData?.length > 0) {
          formData.append("oldMenuData", OldData);
        }
        // for (let i = 0; i < menuArray?.length; i++) {
        //   if (restMenu.length > 0) {
        //     if (menuArray[i] === "") {
        //       toast.dismiss();
        //       toast.error("please upload menu title ");
        //       flag = false
        //       return;
        //     }
        //   }
        // }
        // if (flag === false) {

        //   return;
        // }
      }

      formData.append("venueName", values.venueName);
      formData.append("slugName", values.slugName);
      formData.append("metaTitle", values.metaTitle);
      formData.append("keyWords", values.keyWords);
      formData.append("metaDescription", values.metaDescription);
      formData.append("description", values.description);
      formData.append("capacity", values.capacity);
      formData.append("dimension", values.dimension);

      formData.append("space", values.space);
      formData.append("venueImageTag", values.propertyImgTag);

      formData.append("venueTypeId", values.venueTypeId);
      formData.append("venueCatId", values.venueCatId);



      // og tags

      if (typeof ogImagePlaceholer === "string") {
        const fileName = ogImagePlaceholer.split("/").pop(); //getting the file name i.e pop remove the last emement from the array
        formData.append("ogImage", fileName);
      } else {
        formData.append("ogImage", ogImagePlaceholer);
      }
      formData.append("ogTitle", values.ogTitle);
      formData.append("ogDescription", values.ogDescription);
      formData.append("ogType", values.ogType);
      formData.append("ogUrl", values.ogUrl);
      formData.append("ogSiteName", values.ogSiteName);

      const currentFileIds = maincroppedFile?.currentFileIds || [];

      if (currentFileIds != "") {
        currentFileIds?.forEach((croppedImage, index) => {
          if (!uniqueFileIds.has(croppedImage)) {
            formData.append(`venueImages`, croppedImage);
            uniqueFileIds.add(croppedImage);
          }
        });
      }

      maincroppedImageSorting.map((croppedImage, index) => {
        formData.append("venueImagesSorting", [croppedImage, index]);
      });

      AllPropertiesImages?.map((croppedImage, index) => {
        if (croppedImage.venue_img_id)
          formData.append("venueImagesSortingPrev", [
            croppedImage.venue_img_id,
            index,
          ]);
      });

      if (!deletedMultiImageArray || deletedMultiImageArray.length === 0) {
        // Append images from AllPropertiesImages
        if (AllPropertiesImages.length === 0) {
          formData.append(`presentOldVenueImages`, null);
        } else {
          AllPropertiesImages?.map((oldImgData, index) => {
            formData.append(`presentOldVenueImages`, oldImgData?.image_path);
            //  formData.append(`imagesSortingIndex`, index);
          });
        }
      } else {
        // Append images from deletedMultiImageArray

        deletedMultiImageArray?.map((DltImgData, index) => {
          formData.append(`presentOldVenueImages`, DltImgData);
        });
      }

      if (
        currentFileIds.length === 0 &&
        deletedMultiImageArray.length === 0 &&
        AllPropertiesImages.length === 0
      ) {
        if (propertyImagesRef.current) {
          propertyImagesRef.current.focus();
        }
        toast.dismiss();
        return toast.error("Please add Venue images.");
      }

      if (
        formData.getAll("presentOldVenueImages").length +
          formData.getAll("venueImages").length <
        2
      ) {
        if (propertyImagesRef.current) {
          propertyImagesRef.current.focus();
        }
        toast.dismiss();
        return toast.error("Minimum two venue images are required.");
      }

      if (singleFileProperties === null) {
        formData.append("pdfLink", singleCropImageProp);
      } else {
        formData.append("pdfLink", singleFileProperties);
      }
      // console.log("formDataError",formData)
      if (actions === "save") {
        if (!formDataError) {
          editMutation.mutate({ id, formData });
        }
      } else if (actions === "preview") {
        if (!formDataError) {
          preview.mutate({ id, formData });
        }
      }
    },
    // validateOnBlur: false,
    // validateOnChange: false,
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setValues,
    getFieldProps,
  } = formik;

  // useEffect(() => {
  //   if (Object.values(errors).some((error) => error !== undefined)) {
  //     const firstEmptyField = Object.entries(errors).find(
  //       ([, error]) => error !== undefined && !touched[error.path]
  //     );

  //     if (firstEmptyField) {
  //       const [name] = firstEmptyField;

  //       switch (name) {
  //         case "venueName":
  //           venueNameRef.current.focus();
  //           break;
  //         case "description":
  //           descriptionRef.current.focus();
  //           break;
  //         case "slugName":
  //           slugRef.current.focus();
  //           break;
  //         case "capacity":
  //           capacityRef.current.focus();
  //           break;
  //           // case "dimension":
  //           //   dimensionRef.current.focus();
  //           // break;

  //         case "space":
  //           spaceRef.current.focus();
  //           break;
  //         // case "venueTypeId":
  //         //   venueTypeIdRef.current.focus();
  //         //   break;

  //         default:
  //           break;
  //       }
  //     }
  //   }
  // }, [errors, touched]);

  const handelFocus = () => {
    if (Object.values(errors).some((error) => error !== undefined)) {
      const firstEmptyField = Object.entries(errors).find(
        ([, error]) => error !== undefined && !touched[error.path]
      );

      if (firstEmptyField) {
        const [name] = firstEmptyField;

        switch (name) {
          case "venueName":
            venueNameRef.current.focus();
            break;
          case "description":
            descriptionRef.current.focus();
            break;
          case "slugName":
            slugRef.current.focus();
            break;
          case "capacity":
            capacityRef.current.focus();
            break;
          // case "dimension":
          //   dimensionRef.current.focus();
          // break;

          case "space":
            spaceRef.current.focus();
            break;
          // case "venueTypeId":
          //   venueTypeIdRef.current.focus();
          //   break;

          default:
            break;
        }
      }
    }
    handleSubmit();
  };
  const preview = useMutation(querydata.previewEditVenue, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      const websiteUrl = `${data.previewURL}?isvenuespreview=true&uid=${data.encryptedUserId}&venueId=${id}`;
      window.open(websiteUrl, "_blank");
    },
    onSettled: () => {
      setIsPreviewLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsPreviewLoading(true); // Set loading to true before making the request
    },
  });
  //form update
  const editMutation = useMutation(querydata.updateVenue, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);

      toast.dismiss();
      toast.success(data.message);
      navigate("/manage-venue");
      //  queryClient.invalidateQueries("getSingleProperty");
      queryClient.invalidateQueries("getSinglevenue");
      setmainCroppedImage(null);
      setmainCroppedImageSorting(null);
      setImage(null);
      setmainCroppedImage(null);
      setBannerImage(null);
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  const handleRemoveUnitPlanImage = () => {
    setSingleFileProperties("");
    setSingleCropImageProp("");
    setImage("");
    setUnitPlanPdf("");
    setMediaType("");
  };

  const handleRemoveImage = (e, index) => {
    e.preventDefault();
    const updatedImages = [...maincroppedImage];
    const updatedImagesSorting = [...maincroppedImageSorting];
    const updatedAllPropertyImages = [...AllPropertiesImages];

    const imagetobedeleted = updatedImages[index];
    if (index >= 0 && index < updatedImages.length) {
      // Remove the image at the specified index
      updatedImages.splice(index, 1);

      // Update the state with the new array of images
      //setmainCroppedFile(updatedImages);
      setmainCroppedImage(updatedImages);
      //setmainCroppedImageSorting(updatedImagesSorting);
      setMultiDeletedImgArray(updatedImages);
      setmainCroppedFile(updatedImages);
      // Check if all images have been deleted
      if (updatedImages.length === 0) {
        // Handle the case where all images have been deleted
        setAllPropertiesImages([]);
      }
    } else {
    }
    if (maincroppedFile && maincroppedFile.currentFileIds) {
      const upadatePropFiles = [...maincroppedFile.currentFileIds];

      const fileindex = upadatePropFiles.findIndex(
        (record) => record.imagename === imagetobedeleted
      );

      if (fileindex >= 0) {
        // Remove the image at the specified index

        upadatePropFiles.splice(fileindex, 1);
        // upadatePropFilesnames.splice(index, 1);
        // setmainCroppedFile(upadatePropFiles);
        setmainCroppedFile((prevData) => ({
          ...prevData,
          currentFileIds: [...upadatePropFiles],
        }));
      } else {
      }
      if (fileindex) {
        let imageurl = upadatePropFiles.find(
          (record) => record.imagename === imagetobedeleted
        );
        if (imageurl) {
          imageurl = imageurl.urlname;
        }
        const fileindexSorting = updatedImagesSorting.findIndex(
          (record) => record === imageurl
        );
        if (fileindexSorting >= 0) {
          // Remove the image at the specified index
          updatedImagesSorting.splice(fileindexSorting, 1);
          setmainCroppedImageSorting(updatedImagesSorting);
        } else {
        }
      }
    }
    const fileindexSorting1 = updatedImagesSorting.findIndex(
      (record) => record === imagetobedeleted
    );
    if (fileindexSorting1 >= 0) {
      // Remove the image at the specified index
      updatedImagesSorting.splice(fileindexSorting1, 1);
      setmainCroppedImageSorting(updatedImagesSorting);
    } else {
    }

    const fileindexSorting2 = updatedAllPropertyImages.findIndex(
      (record) => record.image_path === imagetobedeleted
    );
    if (fileindexSorting2 >= 0) {
      updatedAllPropertyImages.splice(fileindexSorting2, 1);
      setAllPropertiesImages(updatedAllPropertyImages);
    } else {
    }
  };

  ///editor start
  const fullEditorRef = useRef(null);
  // console.log("value of the bed typees ids : ",values.bedTypeId)
  useEffect(() => {
    // Assuming fullEditorRef.current is the ReactQuill instance
    const quill = fullEditorRef.current?.getEditor();

    if (quill) {
      // Listen for text-change event in the Quill editor
      quill.on("text-change", () => {
        const descriptionValue = quill.root.innerHTML; // Get the HTML content of the editor
        setFieldValue("description", descriptionValue); // Update the Formik state
      });
    }
  }, [fullEditorRef.current]);

  //   const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'indent', 'link'];
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "code-block",
  ];

  ///editor end
  // console.log("initialValues",initialValues)
  const [venueType, setVenueType] = useState([]);
  // console.log("venueType",venueType)
  const [venueTypeId, setVenueTypeId] = useState(values?.venueTypeId || "");
  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;

    setFieldValue("venueCatId", selectedCategory);
    console.log("selectedCategory", selectedCategory);
    if (selectedCategory === "0") {
      console.log("selectedCategory not", selectedCategory);
      // Reset venue type ID when no category is selected
      setFieldValue("venueTypeId", "");
      setVenueTypeId(""); // Reset in state
    }
    setFieldValue("venueTypeId", "");
    setVenueTypeId("");
  };

  const handleVenueTypeChange = (event) => {
    const selectedVenueType = event.target.value;
    setFieldValue("venueTypeId", selectedVenueType);
    setVenueTypeId(selectedVenueType); // Update state
  };

  useEffect(() => {
    // this effect if to fetch the venues types

    const getVenueTypedata = async () => {
      try {
        if (values?.venueCatId) {
          const data = await querydata.getVenuesTypes(values?.venueCatId);

          setVenueType(data);
        } else {
          setVenueType([]); // Reset venue types when no valid category
          setVenueTypeId(""); // Clear selected venue type
        }
      } catch (error) {
        console.log("value of the error is  : ", error);
      }
    };
    getVenueTypedata();
  }, [values?.venueCatId]);

  const [showPropertyImgModal, setShowPropertyImgModal] = useState(false);
  const [showInnerBannerModal, setShowInnerBannerModal] = useState(false);
  const handleClosePropertyImgTagModal = () => {
    setShowPropertyImgModal(false);
  };

  // Function to close Inner Banner modal
  const handleCloseInnerBannerModal = () => {
    setShowInnerBannerModal(false);
  };

  const handleToggleInnerBannerModal = () => {
    setShowInnerBannerModal((prevState) => !prevState);
  };

  const handleTogglePropertyImgModal = () => {
    setShowPropertyImgModal((prevState) => !prevState);
  };
  const handleSaveImageAltTag = (tagType, value) => {
    // Save the image alt tag value to state

    setFieldValue("propertyImgTag", value);
    setShowPropertyImgModal(false);

    // You can also perform any additional actions here, such as making API calls to save the data to a server
  };

  const handleSaveInnerBannerTag = (tagType, value) => {
    // Save the inner banner tag value to state

    setFieldValue("innerBannerImgTag", value);
    setShowInnerBannerModal(false);
    // You can also perform any additional actions here, such as making API calls to save the data to a server
  };

  if (!getSingleProperty.data) {
    return <div>Loading ... </div>;
  }

  const moveImage = (from, to) => {
    const newImages = [...maincroppedImage];
    const newImagesSorting = [...maincroppedImageSorting];

    const AllImages = [...AllPropertiesImages];
    const allmovedImage = AllImages.splice(from, 1)[0];
    const movedImage = newImages.splice(from, 1)[0];
    const movedImageSorting = newImagesSorting.splice(from, 1)[0];
    newImages.splice(to, 0, movedImage);
    newImagesSorting.splice(to, 0, movedImageSorting);
    if (allmovedImage) AllImages.splice(to, 0, allmovedImage);
    else AllImages.splice(to, 0, movedImage);

    setmainCroppedImage(newImages);
    if (movedImageSorting) setmainCroppedImageSorting(newImagesSorting);
    setAllPropertiesImages(AllImages);
  };

  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <nav className="w-100" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="text-decoration-none" to="/manage-venue">
                    Venues
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Manage Venues
                </li>
              </ol>
            </nav>
            <div className="left-header d-flex align-items-center">
              <Link
                to="/manage-venue"
                className="back-arrow d-inline-block me-2"
              >
                <img src={backarrow} alt="" />
              </Link>
              <h4 className="fw-normal m-0">Manage Venues</h4>
            </div>
          </div>
          <div className="row mt-lg-5 mt-md-4 mt-3">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                  <h5 className="m-0 fw-medium">Edit Your Venue</h5>
                </div>
                <div className="card-body">
                  <div className="upload-file-wrapper">
                    <form action="#" method="get" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-12">
                          <label className="form-label" htmlFor="venueName">
                            Venue Name <span className="mandatory">*</span>
                          </label>
                          <div className="mb-4">
                            <input
                              ref={venueNameRef}
                              type="text"
                              className="form-control py-3 shadow-none px-4"
                              name="venueName"
                              id="venueName"
                              value={values.venueName}
                              onChange={handlePropertyNameChange}
                              maxLength={200}
                              onBlur={handleBlur}
                            />
                          </div>
                          {errors.venueName && touched.venueName ? (
                            <p className="form-error text-danger">
                              {errors.venueName}
                            </p>
                          ) : error.venueName ? (
                            <p className="form-error text-danger">
                              {error.venueName}
                            </p>
                          ) : null}
                        </div>

                        {/* <!-- /col-md-6 --> */}
                        <div className="col-lg-6">
                          <label className="form-label" htmlFor="PropertyType">
                            Venues Catagory
                          </label>
                          <div className="mb-4">
                            <select
                              className="form-control form-select py-3 shadow-none px-4"
                              name="venueCatId"
                              id="PropertyType"
                              onChange={handleCategoryChange}
                              value={values.venueCatId}
                            >
                              <option value="0">---Select---</option>
                              {allPropertiesTypes?.data &&
                                allPropertiesTypes?.data?.data?.map(
                                  (option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.venue_name}
                                    </option>
                                  )
                                )}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <label className="form-label" htmlFor="PropertyType">
                            Venues Types
                          </label>
                          <div className="mb-4">
                            <select
                              // ref={venueTypeIdRef}
                              className="form-control form-select py-3 shadow-none px-4"
                              name="venueTypeId"
                              // onChange={handleChange}
                              onChange={handleVenueTypeChange}
                              disabled={venueType?.data?.length === 0}
                              value={venueTypeId}
                              // defaultValue={values.venueTypeId}
                            >
                              <option value="0">---Select---</option>
                              {venueType &&
                                venueType?.data &&
                                venueType?.data?.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.venue_type}
                                  </option>
                                ))}
                            </select>
                          </div>
                          {/* {errors.venueTypeId && touched.venueTypeId ? (
                            <p className="form-error text-danger">
                              {errors.venueTypeId}
                            </p>
                          ) : error.venueTypeId ? (
                            <p className="form-error text-danger">
                              {error.venueTypeId}
                            </p>
                          ) : null} */}
                        </div>
                      </div>

                      <div className="col-12">
                        <label className="form-label" htmlFor="Description">
                          Venue Description <span className="mandatory">*</span>
                        </label>
                        <div className="mb-4">
                          <textarea
                            ref={descriptionRef}
                            name="description"
                            id="Description"
                            rows="3"
                            className="form-control py-3 shadow-none px-4"
                            value={values.description}
                            onChange={handleChange}
                            // maxLength={255}
                          ></textarea>
                        </div>
                        {errors.description && touched.description ? (
                          <p className="form-error text-danger">
                            {errors.description}
                          </p>
                        ) : error.description ? (
                          <p className="form-error text-danger">
                            {error.description}
                          </p>
                        ) : null}
                      </div>

                      <div className="col-12 mb-4">
                        <label
                          className="form-label mb-3"
                          htmlFor="UploadProperty"
                        >
                          Upload property images{" "}
                          <span className="mandatory">*</span>
                          <span className="toolIcon ms-2">
                            <ToolLink
                              title="Minimum 2 images are required and these can be rearranged by dragging and dropping them in desired position."
                              id="t-1"
                            >
                              <BsFillInfoCircleFill />
                            </ToolLink>{" "}
                          </span>
                        </label>
                        <a
                          title="Add image alt tag"
                          className="btn image-tag"
                          onClick={() =>
                            handleTogglePropertyImgModal("PROPERTYIMGTAG")
                          }
                        >
                          <HiPlus />
                        </a>
                        <DndProvider backend={HTML5Backend}>
                          <div className="uploaded-property-img d-flex flex-wrap">
                            {maincroppedImage?.length === 0 && (
                              <label
                                htmlFor="UploadCover"
                                className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                onClick={() =>
                                  showCropMediaModal(
                                    "",
                                    "multipleImageSectionBtn"
                                  )
                                }
                                ref={propertyImagesRef}
                                tabIndex={-1}
                              >
                                <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                  >
                                    Upload image from gallery
                                  </button>
                                </div>
                              </label>
                            )}
                            {maincroppedImage?.length > 0 &&
                              maincroppedImage.map((imageURL, index) => (
                                <div
                                  key={index}
                                  className="property-img uploaded d-flex justify-content-center align-items-center position-relative"
                                  // style={{
                                  //   height: "200px",
                                  //   width: "200px",
                                  //   position: "relative",
                                  // }}
                                >
                                  <div className="upload-img">
                                    <DragAbleImage
                                      maincroppedImage={imageURL}
                                      moveImage={moveImage}
                                      index={index}
                                      videoImg={videoImg}
                                    />
                                    {/* <a href={imageURL} target="_blank">
                                    <img
                                      // src={imageURL}
                                      src={
                                        imageURL.endsWith(".mp4")
                                          ? videoImg
                                          : imageURL
                                      }
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </a> */}
                                    {/* <div
                                      style={{
                                        position: "absolute",
                                        top: "0",
                                        right: "0",
                                      }}
                                    > */}
                                    <button
                                      className="remove-image-button position-absolute end-0 top-0"
                                      onClick={(e) =>
                                        handleRemoveImage(e, index)
                                      }
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                    {/* </div> */}
                                  </div>
                                </div>
                              ))}

                            {maincroppedImage?.length > 0 && (
                              <div className="property-img d-flex justify-content-center align-items-center position-relative">
                                <div
                                  className="upload-img"
                                  onClick={() =>
                                    showCropMediaModal(
                                      "",
                                      "multipleImageSectionBtn"
                                    )
                                  }
                                >
                                  <i className="fa fa-plus"></i>
                                </div>
                              </div>
                            )}
                          </div>
                        </DndProvider>
                      </div>

                      <div className="col-md-12">
                        <label className="form-label" htmlFor="PropertyUnit">
                          Floor Plan
                        </label>
                        <div className="mb-4 uploaded-property-img">
                          {!singleCropImageProp && (
                            <label
                              htmlFor="UploadCover"
                              className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                              onClick={() =>
                                showCropMediaModal("", "singleUnitPlanImage")
                              }
                            >
                              <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                >
                                  Choose from gallery
                                </button>
                              </div>
                            </label>
                          )}

                          {singleCropImageProp && (
                            <div
                              className="uploaded-img-view"
                              style={{
                                height: "200px",
                                width: "200px",
                                position: "relative",
                              }}
                            >
                              <div>
                                <a href={singleCropImageProp} target="_blank">
                                  <img
                                    src={
                                      singleCropImageProp.endsWith(".pdf")
                                        ? pdfIcon
                                        : singleCropImageProp
                                    }
                                    // src={singleCropImageProp}
                                    style={
                                      singleCropImageProp.endsWith(".pdf")
                                        ? {
                                            width: "68%",
                                            height: "68%",
                                          }
                                        : {
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                          }
                                    }
                                    // style={{
                                    //   width: "100%",
                                    //   height: "100%",
                                    //   objectFit: "cover",
                                    // }}
                                  />
                                </a>
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    right: "0",
                                  }}
                                >
                                  <button
                                    className="remove-image-button"
                                    onClick={() => handleRemoveUnitPlanImage()}
                                  >
                                    <i className="fa fa-times"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* </>
                            )} */}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label className="form-label" htmlFor="PropertyUnit">
                          Seating Plan
                        </label>
                        <VenueSittingPlan
                          showCropMediaModal={showCropMediaModal}
                          setFieldValue={setFieldValue}
                          name={getFieldProps("sittingPlanPdfName").name}
                          value={getFieldProps("sittingPlanPdfName").value}
                          maxLength={20}
                          initialValues={initialValues}
                          values={values}
                          formik={formik}
                          restMenu={restMenu}
                          setrestMenu={setrestMenu}
                        />
                      </div>
                      <div className="col-md-12 mb-4">
                        <label className="form-label" htmlFor="Name">
                          Slug
                          <span className="mandatory">*</span>
                        </label>

                        <div className="bs-example">
                          <input
                            onChange={handleChange}
                            type="text"
                            maxLength={100}
                            name="slugName"
                            value={values?.slugName?.trim()}
                            id="slugName"
                            className="form-control py-3 shadow-none px-4"
                            data-role="tagsinput"
                            onKeyPress={handleKeyPress}
                            ref={slugRef}
                          />
                        </div>
                        {errors.slugName && touched.slugName ? (
                          <p className="form-error text-danger">
                            {errors.slugName}
                          </p>
                        ) : error.slugName ? (
                          <p className="form-error text-danger">
                            {error.slugName}
                          </p>
                        ) : null}
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label
                              className="form-label"
                              // htmlFor="OfferName"
                            >
                              Capacity{" "}
                            </label>
                            <input
                              name="capacity"
                              value={values.capacity}
                              onChange={handleChange}
                              type="text"
                              id="capacity"
                              className="form-control py-3 shadow-none px-4"
                              maxLength={70}
                              ref={capacityRef}
                            />
                          </div>
                          {errors?.capacity && touched.capacity ? (
                            <p className="form-error text-danger">
                              {errors?.capacity}
                            </p>
                          ) : error.capacity ? (
                            <p className="form-error text-danger">
                              {error?.capacity}
                            </p>
                          ) : null}
                        </div>

                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label
                              className="form-label"
                              // htmlFor="OfferName"
                            >
                              Space{" "}
                            </label>
                            <input
                              name="space"
                              value={values.space}
                              onChange={handleChange}
                              type="text"
                              id="Space"
                              className="form-control py-3 shadow-none px-4"
                              maxLength={70}
                              ref={spaceRef}
                            />
                          </div>
                          {errors?.space && touched.space ? (
                            <p className="form-error text-danger">
                              {errors?.space}
                            </p>
                          ) : error.space ? (
                            <p className="form-error text-danger">
                              {error?.space}
                            </p>
                          ) : null}
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label
                              className="form-label"
                              // htmlFor="OfferName"
                            >
                              Dimension in feet (LxBxH)
                            </label>
                            <input
                              name="dimension"
                              value={values.dimension}
                              onChange={handleChange}
                              type="text"
                              id="dimension"
                              className="form-control py-3 shadow-none px-4"
                              maxLength={70}
                              // ref={dimensionRef}
                            />
                          </div>
                          {/* {errors?.dimension && touched.dimension ? (
                            <p className="form-error text-danger">
                              {errors?.dimension}
                            </p>
                          ) : error.dimension ? (
                            <p className="form-error text-danger">
                              {error?.dimension}
                            </p>
                          ) : null} */}
                        </div>
                      </div>

                      <div className="col-12">
                        <ul
                          className="nav nav-pills atmos-tabs d-flex flex-wrap pt-3 border-bottom pb-3"
                          id="myTab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link text-uppercase active"
                              id="Amenities-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#Amenities-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="Amenities-tab-pane"
                              aria-selected="false"
                            >
                              Facilities
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link text-uppercase"
                              id="highlight-extra-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#highlight-extra-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="highlight-extra-tab-pane"
                              aria-selected="false"
                            >
                              Features & services
                            </button>
                          </li>

                          {/* OG TAGS ANDMETA TITLE  */}
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link text-uppercase"
                              id="highlight-extra-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#meta-tags"
                              type="button"
                              role="tab"
                              aria-controls="highlight-extra-tab-pane"
                              aria-selected="false"
                            >
                              Meta Tags
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link text-uppercase"
                              id="highlight-extra-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#Open-Graph"
                              type="button"
                              role="tab"
                              aria-controls="highlight-extra-tab-pane"
                              aria-selected="false"
                            >
                              OG Tags
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content mt-4 mb-0" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="Amenities-tab-pane"
                          role="tabpanel"
                          aria-labelledby="Amenities-tab"
                          tabIndex="0"
                        >
                          <AmenitiesTab1
                            amenitiesMyData={initialValues.amenitiesId}
                            values={values}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            error={error}
                            errors={errors}
                            touched={touched}
                            showCropMediaModal={showCropMediaModal}
                            amenityFileData={amenityFileData}
                            setAmenityFile={setAmenityFile}
                            amenityCropImage={amenityCropImage}
                            setAmenityCropImage={setAmenityCropImage}
                            field={formik.getFieldProps("amenitiesId")}
                          />
                        </div>
                        <div
                          className="tab-pane fade"
                          id="highlight-extra-tab-pane"
                          role="tabpanel"
                          aria-labelledby="highlight-extra-tab"
                          tabIndex="0"
                        >
                          <EditVenueFeatures
                            heading={"Features & services"}
                            labelHeading={"Edit Features & services"}
                            placeholder={"Enter Features & services"}
                            field={formik.getFieldProps("features")}
                            values={values}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            setValues={setValues}
                            signatureFeaturesData={initialValues.features}
                          />
                        </div>
                        {/* og tags  */}

                        <div
                          className="tab-pane fade"
                          id="Open-Graph"
                          role="tabpanel"
                          aria-labelledby="Open-Graph"
                          tabIndex="0"
                        >
                          <OGtags
                            handelChange={handleChange}
                            values={values}
                            ogImage={ogImagePlaceholer}
                            setOgimage={setOgImage}
                            showCropMediaModal={showCropMediaModal}
                          />
                        </div>

                        <div
                          className="tab-pane fade"
                          id="meta-tags"
                          role="tabpanel"
                          aria-labelledby="Amenities-tab"
                          tabIndex="0"
                        >
                          <div className="col-md-12 mb-4">
                            <label className="form-label">Keywords </label>
                            <input
                              name="keyWords"
                              value={values.keyWords}
                              onChange={handleChange}
                              type="text"
                              id="Keywords"
                              className="form-control py-3 shadow-none px-4"
                            />
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label
                                  className="form-label"
                                  // htmlFor="OfferName"
                                >
                                  Meta Title{" "}
                                </label>
                                <input
                                  name="metaTitle"
                                  value={values.metaTitle}
                                  onChange={handleChange}
                                  type="text"
                                  id="metaTitle"
                                  className="form-control py-3 shadow-none px-4"
                                  maxLength={70}
                                />
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label
                                  className="form-label"

                                  // htmlFor="OfferName"
                                >
                                  Meta Description{" "}
                                </label>
                                <input
                                  name="metaDescription"
                                  value={values.metaDescription}
                                  onChange={handleChange}
                                  type="text"
                                  id="metaDescription"
                                  className="form-control py-3 shadow-none px-4"
                                  maxLength={170}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 text-end mb-2 mt-lg-5 mt-4">
                        <Link
                          to={`/manage-venue`}
                          value="Cancel"
                          className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                        >
                          Cancel
                        </Link>
                        <a
                          // href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setActions("preview");
                            handelFocus();
                            // handleSubmit();
                          }}
                          className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2 ms-2"
                        >
                          {isPreviewLoading ? (
                            <div
                              class="spinner-border text-primary"
                              role="status"
                            ></div>
                          ) : (
                            "PREVIEW"
                          )}
                        </a>
                        <Button
                          type="submit"
                          className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2 ms-2"
                          onClick={(e) => {
                            e.preventDefault();
                            setActions("save");
                            // handleSubmit();
                            handelFocus();
                          }}
                        >
                          {isLoading ? (
                            <div
                              class="spinner-border text-primary"
                              role="status"
                            ></div>
                          ) : (
                            "Update"
                          )}
                        </Button>
                        {/* <input
                          onClick={handleSubmit}
                          type="submit"
                          value="Save"
                          className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2 ms-2"
                        /> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal size="xl" show={cropMediaModal} onHide={closeCropMediaModal}>
        <Modal.Header className="modal-header px-5 py-4" closeButton>
          <Modal.Title className="modal-title fs-5">
            {"Select Media"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" overflow-x-auto overflow-y-auto">
          {!folderId && <MediaFolderView setFolderId={setFolderId} />}
          {folderId && !albumId && !websiteId && (
            <ManageAlbumSelection
              folderId={folderId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
            />
          )}
          {isAmenityIcon === "amenityIcon" ? (
            <>
              {folderId && albumId && websiteId && (
                <AlbumViewSelectionDynamicAmenityIcon
                  albumId={albumId}
                  websiteId={websiteId}
                  setAlbumId={setAlbumId}
                  setwebsiteId={setwebsiteId}
                  setFolderId={setFolderId}
                  setCropMediaModal1={setCropMediaModal}
                  setAmenityFile={setAmenityFile}
                  setAmenityCropImage={setAmenityCropImage}
                  setting_value={settingValue}
                />
              )}
            </>
          ) : restMenuValue === "retsmenu" ? (
            <>
              {folderId && albumId && websiteId && (
                <AlbumViewForDinning
                  dataCommingFrom="edit"
                  indexForMenu={indexForMenu}
                  maincroppedThirdFile={""}
                  imgArray={""}
                  currentFileId={""}
                  albumId={albumId}
                  websiteId={websiteId}
                  setAlbumId={setAlbumId}
                  setwebsiteId={setwebsiteId}
                  setFolderId={setFolderId}
                  setCropMediaModal1={setCropMediaModal}
                  setmainCroppedSecImage={""}
                  setMainCroppedIcon={""}
                  setmainCroppedIconFile={""}
                  setmainCroppedSecFile={""}
                  setmainCroppedThirdFile={""}
                  setmainCroppedThirdImage={""}
                  setmainCroppedImage={setmainCroppedImage}
                  checkValForIcon={""}
                  maincroppedImage={maincroppedImage}
                  checkValForSingleImg={checkValForSingleImg}
                  setValForSingleImg={setValForSingleImg}
                  checkValForSingleImg3={""}
                  checkMultipleImageSection={checkMultipleImageSection}
                  setmainCroppedFile={setmainCroppedFile}
                  setMainCroppedBannerImage={setMainCroppedBannerImage}
                  checkValForBannerImage={checkValForBannerImage}
                  // setmainCroppedBannerImageFile={setmainCroppedBannerImageFile}
                  restMenuValue={restMenuValue}
                  setrestMenuValue={setrestMenuValue}
                  setrestMenu={setrestMenu}
                  restMenu={restMenu}
                  restLogoValue={""}
                  setrestLogo={""}
                  setrestLogoValue={""}
                  //for left image
                  setLeftImage={""}
                  leftImageValue={""}
                  setLeftImageValue={""}
                  //for right image
                  setRightImage={""}
                  rightImageValue={""}
                  setRightImageValue={""}
                  // setting_value={
                  //   getAspectRatio &&
                  //   getAspectRatio.data &&
                  //   getAspectRatio.data.data &&
                  //   getAspectRatio.data.data.setting_value
                  // }
                  setting_value={settingValue}
                  //for chef signature image
                  setChefSignatue={""}
                  chefSignatueValue={""}
                  setChefSignatueValue={""}
                />
              )}
            </>
          ) : (
            <>
              {folderId && albumId && websiteId && (
                <AlbumViewSelectionForProperties
                  ogImagePlaceholerValue={ogImagePlaceholerValue}
                  setOgImageValue={setOgImageValue}
                  setOgImage={setOgImage}
                  albumId={albumId}
                  websiteId={websiteId}
                  setAlbumId={setAlbumId}
                  setwebsiteId={setwebsiteId}
                  setCropMediaModal1={setCropMediaModal}
                  setmainCroppedImageSorting={setmainCroppedImageSorting}
                  setmainCroppedImage={setmainCroppedImage}
                  setmainCroppedFile={setmainCroppedFile}
                  setSingleFileProperties={setSingleFileProperties}
                  setSingleCropImageProp={setSingleCropImageProp}
                  checkValForSingleImg={checkValForSingleImg}
                  checkMultipleImageSection={checkMultipleImageSection}
                  setMainCroppedBannerImage={setMainCroppedBannerImage}
                  checkValForBannerImage={checkValForBannerImage}
                  setmainCroppedBannerImageFile={setmainCroppedBannerImageFile}
                  setUnitPlanPdf={setUnitPlanPdf}
                  mediaTypeCode={mediaType}
                  setMediaType={setMediaType}
                  // setting_value={getAspectRatio?.data?.data?.setting_value}
                  setting_value={settingValue}
                  setAllPropertiesImages={setAllPropertiesImages}
                  foredit={true}
                />
              )}
            </>
          )}
        </Modal.Body>
      </Modal>

      <ImageTagModal
        showModalImageTagModal={showPropertyImgModal}
        handleCloseImageTagModal={handleClosePropertyImgTagModal}
        initialImageAltTag={values.propertyImgTag}
        tagType="PROPERTYIMGTAG"
        handleSaveImageAltTag={handleSaveImageAltTag}
        inputName="propertyImgTag"
      />

      {/* <ImageTagModal
        showModalImageTagModal={showInnerBannerModal}
        handleCloseImageTagModal={handleCloseInnerBannerModal}
        initialImageAltTag={values.innerBannerImgTag}
        tagType="INNERBANNER"
        handleSaveImageAltTag={handleSaveInnerBannerTag}
        inputName="innerBannerTag"
      /> */}
    </>
  );
}
